import React from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { moodTheme } from './App.jss';
import { NavigatorContainer } from './navigator';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={moodTheme}>
      <NavigatorContainer />
    </ThemeProvider>
  );
};

export default App;
