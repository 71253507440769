import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './App';
import { editorReducer } from './editor';
import { fetchMediaLibrary, mediaLibraryReducer } from './mediaLibrary';
import { MVisionData, MVisionInterface } from './mvisionIntegration';
import { navigatorReducer } from './navigator';
import * as serviceWorker from './serviceWorker';

const bootstrap = (rootId: string = 'root') => {
  const store = createStore(
    combineReducers({
      navigator: navigatorReducer,
      editor: editorReducer,
    mediaLibrary: mediaLibraryReducer
  }),
  composeWithDevTools(applyMiddleware(thunk))
  );
  
  ReactDOM.render(
    <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById(rootId),
  () => store.dispatch<any>(fetchMediaLibrary())
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

(() =>{
  const useMME = process.env.REACT_APP_USE_MME === 'true';
  console.log(useMME);
  if(useMME) {
    const mvisionInterface: MVisionInterface = {
      open: (root, workgroupId, workgroupName, userId, homeFn) => {
        MVisionData.backFn = homeFn;
        MVisionData.userId = userId;
        MVisionData.workgroupId = workgroupId;
        MVisionData.workgroupName = workgroupName;
        bootstrap(root);
      }
    };
    (window as any).MME= mvisionInterface;
  } else {
    bootstrap();
  }
})()
